export const environment = {
  production: false,

  //Endpoints
  playerCompeteMobile: 'https://m.devcompete.fullswingapps.com/m',
  apiLeagueServer: 'https://devcompeteapi.fullswingapps.com/api',
  apiBaymanagerServer: 'https://devlauncher.fsgapi.net/api/fs',
  apiBaymanagerPanel: 'https://devbaymanager.fsgapi.net',
  authPortal: 'https://devauth.fullswingapps.com',
  hostName: 'devbaymanager.fsgapi.net',

  //Variables
  defaultPage: 'home',
  navName: '_fs_nav_name',

  //Objects
  breakPointScreen: {
    small: 576,
    Medium: 768,
    large: 992,
    extraLarge: 1200,
    xExtraLarge: 1400,
  },

  //Ploicy groups
  companyAdmin: 'companyadmin',
  competeAdmin: 'competeadmin',
  bayManagerAdmin: 'baymanageradmin',
  competePlayer: 'competeplayer',
  adminPortal: 'techadmin',
  residential: 'Users',
};
